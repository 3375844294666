import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["container", "expandable"];

  toggle(event) {
    event.preventDefault();

    this.expandableTargets.forEach(target => {
      target.classList.toggle("d-none");
    });

    this.containerTarget.classList.toggle("collapsed");
  }
}
