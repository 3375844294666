import { Controller } from "@hotwired/stimulus";
import hotkeys from "hotkeys-js";

export default class extends Controller {
  static targets = ["refocus"];

  connect() {
    hotkeys.filter = function (event) {
      return true;
    };

    hotkeys("command+enter", () => {
      this.element.requestSubmit();
    });
  }

  disconnect() {
    hotkeys.unbind("cmd+enter");
  }

  reset() {
    this.element.reset();
    this.refocusTarget.focus();
  }
}
