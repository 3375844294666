import { Controller } from "@hotwired/stimulus";
import Sortable from "sortablejs";

export default class extends Controller {
  connect() {
    this.sortable = Sortable.create(this.element, {
      group: "shared",
      forceFallback: true,
      chosenClass: "card-rotate-left",
      onEnd: this.end.bind(this),
      filter: ".new_card_form",
      preventOnFilter: false,
    });
  }

  end(event) {
    let url = event.item.dataset.url;
    let data = new FormData();
    data.append("position", event.newIndex + 1);

    fetch(url, {
      method: "PATCH",
      headers: {
        "X-CSRF-Token": document.querySelector("[name='csrf-token']").content,
      },
      body: data,
    });
  }
}
